<template>
    <div class="afterSaleDetail">
        <div class="areaName">售后流程</div>
        <div class="flows">
            <el-steps :active="afterOrderDetail.afterOrder.afterOrderStatus" >
             <!-- <el-step :title="index==0? '申请':(index==1 ? '同意':(index==2? '退货退款':'已完成'))" :icon="index==0? 'el-icon-s-check':(index==1? 'el-icon-folder':(index==2? 'el-icon-folder-opened':'el-icon-circle-check'))"  v-for="(item,index) in afterOrderDetail.orderlogList" > -->
                <el-step :title="index==0? '申请':(index==1 ? '同意':(index==2? '退货退款':'已完成'))" :icon="index==0? 'el-icon-s-check':(index==1? 'el-icon-folder':(index==2? 'el-icon-folder-opened':'el-icon-circle-check'))"  v-for="(item,index) in afterOrderDetail.afterTypeList" :key="index">
                <template #description>
                  <div v-if="index == 0">
                    <div>{{ afterOrderDetail.orderlogList[0].userName }}用户申请售后</div>
                    <!-- <div style="display: flex"> 售后类型：<dict-tag :options="dict.type.AfterOrderType" :value="afterOrderDetail.orderlogList[0].afterType" :is-text="true"/></div> -->
                    <div style="display: flex"> 售后类型：{{afterTypes[afterOrderDetail.orderlogList[0].afterType]}}</div>
                  </div>
                  <div v-if="index == 1 && afterOrderDetail.orderlogList.length>=2 ">
                    <div>商家管理员：{{afterOrderDetail.orderlogList[1].userName}}</div>
                    <div> {{afterOrderDetail.orderlogList[1].remark}}</div>
                  </div>
                  <div v-if="index == 2 && afterOrderDetail.orderlogList.length>=3">
                    <div>操作人员：{{afterOrderDetail.orderlogList[2].userName}}</div>
                    <div> {{afterOrderDetail.orderlogList[2].remark}}</div>
                  </div>
                  <div v-if="index == 3  && afterOrderDetail.orderlogList.length>=4">
                    <div>操作人员：{{afterOrderDetail.orderlogList[3].userName}}</div>
                    <div> {{afterOrderDetail.orderlogList[3].remark}}</div>
                  </div>
                </template>
              </el-step>
            </el-steps>
        </div>
        <div class="areaName">售后详情</div>
        <div class="fieldContiner">
            <div class="fieldItem" :class="{'leng':item.leng,'media':item.media}" v-for="(item,i) in afterField" :key="i">
                <div class="label">{{item.label}}</div>
                <div class="val" v-if="!item.media">{{item.val}}</div>
                <div class="val" v-else>
                    <img :src="imgUrl" v-for="(imgUrl,j) in item.val.split(',')" :key="j"/>
                </div>
            </div>
        </div>
        <orderDetailPage :afterSaleOrderId="afterOrderDetail.orderId"></orderDetailPage>
    </div>
</template>

<script>
import orderDetailPage from "./OrderDetail"
import {getAfterSaleDetail} from "@/axios/api"
export default {
    components:{
        orderDetailPage
    },
    data() {
        return {
            afterTypes:['','退货退款','仅退款','更换货物','返厂维修'],//售后类型
            afterOrderDetail:{
                afterOrder:{},
            },
            afterField:[
                {label:"订单id",field:"orderId",val:""},
                {label:"订单sn",field:"orderSn",val:""},
                {label:"售后类型",field:"afterType_",val:""},//spe
                {label:"订单收货人",field:"receiveUser",val:""},
                {label:"手机号码",field:"telephone",val:""},
                {label:"申请时间",field:"createTime_",val:""},//spe
                {label:"备注",field:"remark_",val:"",leng:true},//spe
                {label:"附件",field:"imgUrls_",val:"",leng:true,media:true},//spe
            ]
        }
    },
    methods: {
        gainAfterSaleDetail(){
            getAfterSaleDetail({afterOrderId:this.$route.query.afterOrderId}).then(res=>{
                console.log("售后订单详情",res);
                if(res.code==200){
                    this.afterOrderDetail=res.data;

                    this.afterField.forEach(item=>{
                        for(var key in res.data){
                            if(item.field=="afterType_"){
                                item.val=this.afterTypes[res.data.afterOrder.afterType]
                            }
                            if(item.field=="createTime_"){
                                item.val=res.data.afterOrder.createTime
                            }
                            if(item.field=='remark_'){
                                item.val=res.data.afterOrder.remark
                            }
                            if(item.field=='imgUrls_'){
                                item.val=res.data.afterOrder.imgUrls
                            }
                            if(key==item.field){
                                item.val=res.data[key];
                                break
                            }
                        }
                    })
                }
            })
        }
    },
    created() {
        this.gainAfterSaleDetail();
    },
}
</script>

<style lang="less" scoped>
.afterSaleDetail{
    .areaName{
        font-size: 15px;
        font-weight: 700;
        color: #333;
        border-left: 3px solid rgb(255, 129, 26);
        padding-left: 9px;
    }
    .flows{
        margin-top:12px;
        margin-bottom:20px;
    }
    .fieldContiner{
        margin-top: 12px;
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid #e5e5e5;
        border-left: 1px solid #e5e5e5;
        .fieldItem{
            width: calc(100% / 3);
            &.leng{
                width: calc((100% / 3) * 3)
            }
            &.media{
                height: 100px;
                .val{
                    display:flex;
                    align-items:center;
                    &>img{
                        width:70px;
                        height:70px;
                        object-fit:cover;
                        border-radius:6px;
                        margin-right:10px;
                    }
                }
            }
            display: flex;
            align-items: center;
            height: 36px;
            font-size: 12px;
            border-right: 1px solid #e5e5e5;
            border-bottom: 1px solid #e5e5e5;
            .label{
                width: 120px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #e9f2fa;
                color: #4c4c4c;
                border-right: 1px solid #e5e5e5;
            }
            .val{
                flex: 1;
                height: 100%;
                padding: 0 10px;
                line-height: 36px;
                color: #333;
                background: #fff;
            }
        }
    }

    .orderDetail{
        margin-top: 30px;
    }
}
</style>