<template>
    <div class="orderDetail">
        <div class="operate">
            <el-button type="primary" @click="clickPrint">导出详情</el-button>
        </div>
        <div class="areaName">订单详情</div>
        <div class="fieldContiner">
            <div class="fieldItem" :class="{'leng':item.leng,'max':item.max}" v-for="(item,i) in orderField" :key="i">
                <div class="label">{{item.label}}</div>
                <div class="val">{{item.val}}</div>
            </div>
        </div>
        <div class="lump">
            <div class="lumpName">订单商品</div>
            <div class="tableContiner">
                <el-table
                    border
                    :data="goods_tableData"
                    style="width: 100%">
                    <!-- <el-table-column prop="goodsId" label="商品id" width="120"/> -->
                    <el-table-column prop="goods.sn" label="商品货号" width="200"/>
                    <el-table-column prop="platform" label="归属平台" width="100"/>
                    <el-table-column prop="goods.name" label="商品名称" min-width="280"/>
                    <el-table-column prop="count" label="商品数量" width="80"/>
                    <el-table-column prop="ourShopPrice" label="商品本店价" width="90"/>
                    <el-table-column prop="ourShopPrice" label="本店价金额" width="90">
                        <template #default="scope">
                            <span v-if="scope.row.ourShopPrice">{{scope.row.ourShopPrice * scope.row.count}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="goodsPrice" label="商品售价" width="90"/>
                    <el-table-column prop="goodsPrice" label="售价总金额" width="90">
                        <template #default="scope">
                            <span v-if="scope.row.goodsPrice">{{scope.row.goodsPrice * scope.row.count}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="settlePrice" label="商品结算价" width="90"  v-if="curPage=='purchaseOrder'"/>
                    <el-table-column prop="settlePrice" label="结算价总金额" width="100" v-if="curPage=='purchaseOrder'">
                        <template #default="scope">
                            <span v-if="scope.row.settlePrice">{{scope.row.settlePrice * scope.row.count}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="shippingStatus" label="发货状态" width="80">
                        <template #default="scope">
                            <!-- <dict-tag :options="dict.type.OrderShippingStatus" :value="scope.row.shippingStatus" :is-text="true"/> -->
                            <div>{{['未发货','已发货'][scope.row.shippingStatus]}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="shippingStatus" label="备注" width="240">
                        <template #default="scope">
                            <div>{{scope.row.goods.remark}}</div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                        <template slot-scope="scope">
                            <el-button
                            v-if="form.payStatus === '1' && scope.row.shippingStatus === '0'"
                            size="mini"
                            type="text"
                            @click="changeShippingStatus(scope.row)"
                            v-has-permi="['mall:order:changeShippingStatus']"
                            >发货</el-button>
                            <el-button
                            v-if="form.payStatus === '0'"
                            size="mini"
                            type="text"
                            @click="changePrice(scope.row)"
                            v-has-permi="['mall:order:changePrice']"
                            >修改价格</el-button>
                        </template>
                    </!--> -->
                </el-table>
            </div>
        </div>
        <div class="lump">
            <div class="lumpName">收款信息</div>
            <div class="tableContiner">
                <el-table
                    border
                    :data="orderPay_tableData"
                    style="width: 100%">
                    <el-table-column prop="orderPayId" label="收款单id" width="100"/>
                    <el-table-column prop="source" label="收款来源" width="80">
                        <template #default="scope">
                            <!-- <dict-tag :options="dictObj.source" :value="scope.row.source" :is-text="true"/> -->
                            <div>{{['','后台','前台'][scope.row.source]}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="receiveAccountType" label="收款方式" width="100">
                        <template #default="scope">
                            <!-- <dict-tag :options="dict.type.ReceiveAccountType" :value="scope.row.receiveAccountType" :is-text="true"/> -->
                            <!-- <div>{{comput_receiveAccountType(scope.row.receiveAccountType)}}</div> -->
                            <div>{{receiveAccountTypeList[scope.row.receiveAccountType-1].dictLabel}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="receiveAmount" label="收款金额" width="80"/>
                    <el-table-column prop="receiveAccount.accountNumber" label="收款账户" width="90"/>
                    <el-table-column prop="createUser" label="创建人员" width="100">
                        <template #default="scope">
                            <span v-if="scope.row.createUser">{{scope.row.createUser.nickName || scope.row.createUser.telephone}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="payAccount" label="付款账户" width="120"/>
                    <el-table-column prop="payTime" label="收款时间" width="90"/>
                    <el-table-column prop="transSerialNumber" label="交易流水号" min-width="100"/>
                    <el-table-column prop="remark" label="备注" width="130"/>
                    <el-table-column prop="auditUser" label="审核操作员" width="100">
                        <template #default="scope">
                            <span v-if="scope.row.auditUser">{{scope.row.auditUser.nickName || scope.row.auditUser.telephone}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="auditTime" label="审核时间" width="90"/>
                    <el-table-column label="审核结果" align="center" prop="auditStatus" width="80">
                        <template #default="scope">
                            <!-- <dict-tag :options="dictObj.auditStatus" :value="scope.row.auditStatus"/> -->
                            <div>{{['待审核','审核通过','审核不通过'][scope.row.auditStatus]}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="拒绝原因" align="center" prop="auditReason" />
                    <!-- <el-table-column label="关联凭证" align="center" prop="proof" width="80">
                        <template #default="scope">
                            <el-button v-if="scope.row.proof" type="text" @click="onPreview(scope.row.proof)">查看</el-button>
                            <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="[viewerUrl]" />
                        </template>
                    </el-table-column> -->
                    <el-table-column label="是否到账" align="center" prop="isToAccount" width="80">
                        <template #default="scope">
                            <!-- <dict-tag :options="dict.type.YesOrNo" :value="scope.row.isToAccount"/> -->
                            <div>{{scope.row.isToAccount=='Y'?"是":"否"}}</div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="lump" v-if="false">
            <div class="lumpName">平台结算信息</div>
            <div class="tableContiner">
                <el-table
                    border
                    :data="settlePay_tableData"
                    style="width: 100%">
                    <el-table-column prop="orderPayId" label="收款单id" width="100"/>
                    <el-table-column prop="source" label="收款来源" width="80">
                        <template #default="scope">
                            <!-- <dict-tag :options="dictObj.source" :value="scope.row.source" :is-text="true"/> -->
                            <div>{{['','后台','前台'][scope.row.source]}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="receiveAccountType" label="结算方式" width="100">
                        <template #default="scope">
                            <!-- <dict-tag :options="dict.type.ReceiveAccountType" :value="scope.row.receiveAccountType" :is-text="true"/> -->
                            <div>{{receiveAccountTypeList[scope.row.receiveAccountType-1].dictLabel}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="receiveAmount" label="结算金额" width="120"/>
                    <el-table-column prop="payAccount" label="付款账户" width="140"/>
                    <el-table-column prop="payTime" label="结算时间" width="90"/>
                    <el-table-column label="审核结果" align="center" prop="auditStatus" width="80">
                        <template #default="scope">
                            <!-- <dict-tag :options="dictObj.auditStatus" :value="scope.row.auditStatus"/> -->
                            <div>{{['待审核','审核通过','审核不通过'][scope.row.auditStatus]}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="拒绝原因" align="center" prop="auditReason" />
                    <!-- <el-table-column label="关联凭证" align="center" prop="proof" width="80">
                        <template #default="scope">
                            <el-button v-if="scope.row.proof" type="text" @click="onPreview(scope.row.proof)">查看</el-button>
                            <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="[viewerUrl]" />
                        </template>
                    </el-table-column> -->
                    <el-table-column label="是否到账" align="center" prop="isToAccount" width="80">
                        <template #default="scope">
                            <!-- <dict-tag :options="dict.type.YesOrNo" :value="scope.row.isToAccount"/> -->
                            <div>{{scope.row.isToAccount=='Y'?"是":"否"}}</div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="lump">
            <div class="lumpName">订单备注</div>
            <div class="tableContiner">
                <el-table
                    border
                    :data="remark_tableData"
                    style="width: 100%">
                    <el-table-column prop="createTime" label="日期时间"/>
                    <el-table-column prop="createUser" label="备注人">
                        <template #default="scope">
                            <span v-if="scope.row.createUser">{{scope.row.createUser.nickName || scope.row.createUser.telephone}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="remark" label="备注内容" min-width="200"/>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import {getOrderDetail,getDictTypeList} from "@/axios/api"
export default {
    props:['afterSaleOrderId'],
    data() {
        return {
            orderField:[
                {label:"订单id",field:"orderId",val:""},
                {label:"订单sn",field:"orderSn",val:""},
                {label:"订单时间",field:"createTime",val:""},
                {label:"订单收货人",field:"receiveUser",val:""},
                {label:"手机号码",field:"telephone",val:""},
                {label:"备用电话",field:"bakTelephone",val:""},
                {label:"公司全称",field:"buyBusiness_",val:""},//spe
                {label:"主账号",field:"mainUser_",val:""},//spe
                {label:"下单账号(昵称)",field:"tel_nick",val:""},//spe
                {label:"订单email",field:"email",val:""},
                {label:"订单期望到货时间",field:"expectedArrivalDate",val:""},
                {label:"订单附言",field:"remark",val:""},
                {label:"订单状态",field:"orderStatus",val:""},//spe
                {label:"订单类型",field:"type_",val:""},//spe
                {label:"订单地址",field:"pro_ci_co",val:""},//spe
                {label:"发货状态",field:"shipStatus",val:""},//spe
                {label:"",field:"",val:""},
                {label:"",field:"",val:""},
                // {label:"订单金额",field:"amount+",val:"",leng:true},//spe
                // {label:"订单总金额",field:"amount",val:""},
                // {label:"结算明细",field:"settleAmount+",val:"",leng:true},//spe
                // {label:"结算状态",field:"settle_status",val:""},//spe
            ],
            goods_tableData:[],
            orderPay_tableData:[],
            settlePay_tableData:[],
            remark_tableData:[],
            
            curPage:'',
            receiveAccountTypeList:[],
        }
    },
    methods: {
        gainDetail(){
            getOrderDetail({
                orderId:this.$route.query.orderId||this.afterSaleOrderId,
                busType:this.curPage=='purchaseOrder'?2:1
            }).then(res=>{
                console.log("订单详情",res);
                if(res.code==200){
                    this.orderField.forEach(item=>{
                        for(var key in res.data){
                            if(item.field=="tel_nick"){
                                item.val=res.data.user.telephone+"("+(res.data.user.nickName||'')+")"
                            }
                            if(item.field=="orderStatus"){
                                item.val=['未支付','已支付','部分支付'][res.data.payStatus]
                            }
                            if(item.field=="type_"){
                                item.val=['','批发订单','客户订单'][res.data.type]
                            }
                            if(item.field=='pro_ci_co'){
                                item.val=res.data.provinceName+res.data.cityName+res.data.countyName+res.data.address
                            }
                            if(item.field=='shipStatus'){
                                item.val=['未发货','已发货','部分发货'][res.data.shippingStatus]
                            }
                            if(item.field=='amount+'){
                                item.val=`${res.data.amount}(商品总金额)+${res.data.transportAmount}(运费)+${res.data.fragileAmount}(易碎品费)+${res.data.deliveryInstallAmount}(配送安装费)-${res.data.payAmount}(已收款)=${res.data.shouldReceivedAmount}(应收款)`
                            }
                            if(item.field=='settleAmount+'){
                                item.val=`${res.data.settleAmount}(商品结算金额)+${res.data.transportAmount}(运费)+${res.data.fragileAmount}(易碎品费)+${res.data.deliveryInstallAmount}(配送安装费)-0.00(折扣)-0.00(返点)-${res.data.paySettleAmount}(已结算)=${res.data.toBeSettledAmount}(待结算)`
                            }
                            if(item.field=='settle_status'){
                                item.val=['未结算','已结算','部分结算'][res.data.settleStatus]
                            }
                            if(item.field=='buyBusiness_'){
                                item.val=res.data.buyBusiness?res.data.buyBusiness.name:""
                            }
                            if(item.field=='mainUser_'){
                                item.val=res.data.mainUser?res.data.mainUser.telephone:""
                            }
                            if(key==item.field){
                                item.val=res.data[key];
                                break
                            }
                        }
                    })
                    this.goods_tableData=res.data.orderGoodsList;
                    this.orderPay_tableData=res.data.orderPayList;
                    this.settlePay_tableData=res.data.settlePayList;
                    this.remark_tableData=res.data.orderRemarkList;
                }
            })
        },
        clickPrint(order){
            // this.$router.push({
            //     path:"/personal/previewDetail",
            //     query:{orderId:order.orderId}
            // })

            var href=location.href.substring(0,location.href.indexOf("personal")-1);
            window.open(href+'/previewDetail?orderId='+this.$route.query.orderId+'&c='+this.$route.query.c,"__blank")
        },
        // 获取字典数据
        gainDict(){
            getDictTypeList({dictType:"ReceiveAccountType"}).then(res=>{
                console.log("收款方式字典",res);
                if(res.code==200){
                    this.receiveAccountTypeList=res.data;
                }
            })
        },
        comput_receiveAccountType(type){
            for(var i=0;i<this.receiveAccountTypeList.length;i++){
                if(type==this.receiveAccountTypeList[i].dictValue){
                    return this.receiveAccountTypeList[i].dictLabel
                }
            }
        }
    },
    created() {
        if(this.$route.query.c==1){
            this.curPage='purchaseOrder';
            
            this.orderField.splice(this.orderField.length-2,1,{label:"结算状态",field:"settle_status",val:""})
            this.orderField.push({label:"结算明细",field:"settleAmount+",val:"",leng:true,max:true})
            // this.orderField.push({label:"结算状态",field:"settle_status",val:""})
        }else{
            this.curPage='';

            this.orderField.push({label:"订单金额",field:"amount+",val:"",leng:true})
            this.orderField.push({label:"订单总金额",field:"amount",val:""})
        }
        this.gainDict();
        this.gainDetail();
    },
    watch:{
        'afterSaleOrderId'(){
            this.gainDetail()
        }
    }
}
</script>

<style lang="less" scoped>
.orderDetail{
    .operate{
        margin-bottom: 20px;
        text-align: right;
    }
    .areaName{
        font-size: 15px;
        font-weight: 700;
        color: #333;
        border-left: 3px solid rgb(255, 129, 26);
        padding-left: 9px;
    }
    .fieldContiner{
        margin-top: 12px;
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid #e5e5e5;
        border-left: 1px solid #e5e5e5;
        .fieldItem{
            width: calc(100% / 3);
            &.leng{
                width: calc((100% / 3) * 2)
            }
            &.max{
                width: 100%;
            }
            display: flex;
            align-items: center;
            height: 36px;
            font-size: 12px;
            border-right: 1px solid #e5e5e5;
            border-bottom: 1px solid #e5e5e5;
            .label{
                width: 120px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #e9f2fa;
                color: #4c4c4c;
                border-right: 1px solid #e5e5e5;
            }
            .val{
                flex: 1;
                height: 100%;
                padding: 0 10px;
                line-height: 36px;
                color: #333;
                background: #fff;
            }
        }
    }
    .lump{
        margin-top: 25px;
        .lumpName{
            font-size: 12px;
            font-weight: 700;
            color: #606266
        }
        .tableContiner{
            margin-top: 12px;
            /deep/.el-table{
                thead th{
                    background: #e9f2fa;
                    border-color: #dfe6ec;
                }
            }
        }
    }
}
</style>